<template>
    <Layout id="layouttttttttttt">
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1><i class="mdi mdi-file-check"
                                        style="transform: scale(1.7);"></i> Manage your regulations</h1>
                        <label style="font-weight: 350;"><i class="mdi mdi-information"></i> This page allows you to add regulations records via an excel file or . </label>
                        <div v-if="importInvoice" class="accueil">
                            <div style="display: flex;justify-content: space-between;">
                                <button @click="importInvoice = false" type="button" class="btn btn-outline btn-info mb-3"><span
                                    class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline"
                                        style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                                <button @click="generateExcelFile()" type="button" class="btn btn-outline btn-info mb-3"><span
                                    class="btn-label"><i class="mdi mdi-arrow-down-bold-box-outline"
                                        style="transform: scale(1.7);"></i>
                                </span>Get template </button>
                            </div>
                            <div class="form-container mb-2">

                                <h2 style="margin-bottom: 20px">Add Regulations</h2>
                                <span> </span>

                                <div>
                                    <button class="file-upload-button" @click="handleFileUpload">
                                        Import
                                    </button>
                                </div>
                            </div>


                            <div v-if="data.length" class="table-responsive mt-5">
                                <table class="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox" v-model="toggleValue" @change="toggleList()" /></th>
                                            <th v-for="label in dataLabels">{{ label }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(entry, index) in data" :key="`tabledata_${index}`"
                                            @click="toggleCheckbox(index)">
                                            <th scope="row"><input type="checkbox" v-model="selectedIndexes[index]" /></th>
                                            <td v-for="(cell, indexx) in entry" :key="`celldata_${indexx}_${index}`"> {{
                                                cell }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            

                            <div class="mt-5 mb-5 text-center">
                                <button type="submit" v-if="fileUploaded" @click="submitHandle"
                                    class="btn w-25 btn-outline-primary btn-bordered-primary">Add</button>
                            </div>

                        </div>
                        <div v-else-if="addingInvoice">
                            <button @click="addingInvoice = false;" type="button" class="btn btn-outline btn-info mb-3"><span
                                    class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline"
                                        style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                            <form @submit.prevent="preventsubmit">
                                <div class="formulaire">
                                    <div style="margin-left: auto; margin-right: auto; width: 50%;">
                                    <b-form-group label="ID" label-for="ID">
                                        <b-form-input  v-model="id" type="text" id="ID" >
                                          
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Type" label-for="type">
                                        <b-form-input  v-model="Type" type="text" id="type" >
                                          
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Amount" label-for="Amount">
                                        <b-form-input  v-model="Amount" type="number" id="Amount" 
                                            ></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Payment method" label-for="method">
                                      <multiselect id="method"  v-model="Payment_method" :options="methods"></multiselect>
                                    </b-form-group>
                                    <b-form-group label="Date" label-for="date">
                                        <b-form-input  v-model="Ddate" type="date" id="date" 
                                            ></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Due Date" label-for="echeance">
                                        <b-form-input  v-model="echeance" type="date" id="echeance" 
                                            ></b-form-input>
                                    </b-form-group>
                                    
                                    <div class="mt-5 mb-5 text-center">
                                    <button type="submit" 
                                        class="btn w-25 btn-outline-primary btn-bordered-primary" @click="ajouterFacture()">Add</button>
                                </div>
                                </div>
                                </div>
                                
                                
                            </form>
            </div>

                        <div v-else>
                            <p class="text-muted font-13 mb-4"></p>
                            <div class="row mb-md-4">
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm"
                                                :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <label class="d-inline-flex align-items-center">Add new Regulations</label>
                                    <button type="button" class="btn btn-primary ml-2" @click="importInvoice = true"><i class="mdi mdi-microsoft-excel"
                                        style="transform: scale(1.7);"></i></button>
                                            <button type="button" class="btn btn-primary ml-2" @click="addingInvoice = true"><i class="mdi mdi-format-align-left"
                                        style="transform: scale(1.7);"></i></button>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                class="form-control form-control-sm ml-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive table-striped mb-0">
                                <b-table style="padding: .5rem;" :items="listeFacture" :fields="fields" responsive="sm"
                                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                    @filtered="onFiltered"></b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import { read, utils, write } from 'xlsx';
import Layout from "./layouts/main";
import axios from "axios"
import swal from "sweetalert"
import Multiselect from 'vue-multiselect'

export default {
    data() {
        return {
            regulation:{} ,
            regulations:[],
            CompanyName:localStorage.getItem('societe').slice(1, -1),
            id:"",
            Type: "",
            Ddate:"",
            Payment_method: "",
            Amount: "",
            echeance: "",   
            methods: ['In cash', 'By check', 'By bank card','By bank transfer','By SEPA direct debit','By commercial paper'],
            //InvoiceStatuses: ['Pending', 'Paid', 'Overdue', 'Refunded', 'Partially Refunded', 'Partially Paid', 'Cancelled'],
            InvoiceStatus: '',
            toggleValue: true,
            dataLabels: [],
            selectedIndexes: [],
            data: [],
            listeFacture: [],
            importInvoice: false,
            addingInvoice: false,
            fileUploaded: false,


            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            fields: [
                {
                    key: "id",                    
                },
                {
                    key: "Type",
                    sortable: true
                },
                {
                    key: "Date",
                    sortable: true,
                },
                {
                    key: "Payment_method",
                    sortable: true,
                },
                {
                    key: "Amount",
                    sortable: true,
                },
                {
                    key: "echeance",
                    sortable: true,
                }
            ],
        };
    },
    components: {
        Layout,
        Multiselect,
    },
    async mounted() {
        // axios.get(`https://finex.4help.tn/api/v1/regulations/${localStorage.getItem('societe').slice(1, -1)}`).then((res) => {
        //     this.CustNames = res.data.map((client) => client["Client Name"]);
        // })
        await this.getFactureVente()
    },
    computed: {
        rows() {
            return this.listeFacture.length;
        },
    },
    methods: {
        ajouterFacture() {
            this.regulation["Societe"] = this.CompanyName
            this.regulation["Type"] = this.Type
            this.regulation["id"] = this.id
            this.regulation["Date"] = this.Ddate
            this.regulation["Payment_method"] = this.Payment_method
            this.regulation["echeance"] = this.echeance
            this.regulation["Amount"] = this.Amount
            this.regulations[0]=this.regulation
            console.log("this regulations",this.regulations)
            axios.post("https://finex.4help.tn/api/v1/regulations", this.regulation).then(async (res) => {
                if (res.status === 200 || res.status === 201) {
                    
                    swal({
                        title: "Success",
                        text: `Successfully added ${this.regulation["id"]} !`,
                        icon: "success",
                        
                    });
                    this.getFactureVente();
                    this.currentPage = 1;
                    this.addingInvoice = false;
                    this.data = [];
                    this.fileUploaded = false;
                    // window.location.reload(true);
                    
                }else{
                    swal({
                        title: "Error",
                        text: `Failed to add ${this.regulation["id"]} !`,
                        icon: "error",
                    })
                }
            }).finally(()=>{
                
            })
         
        },
        generateExcelFile() {
    // Create a new workbook
    const workbook = utils.book_new();

    // Create a data array containing the row data
    const data = [['Type', 'Date','Payment_method','Amount','echeance','id','Societe']]; // Modify this array with your row data

    // Add the data array to a worksheet
    const worksheet = utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate Excel file binary data
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });

    // Convert Excel buffer to Blob
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create a download link element
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'template.xlsx';

    // Append the download link to the body and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  },
        toggleList() {
            this.selectedIndexes = Array.from({ length: this.data.length }, () => this.toggleValue)
        },
        toggleCheckbox(index) {
            this.$set(this.selectedIndexes, index, !this.selectedIndexes[index]);
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getFactureVente() {
            this.listeFacture = await axios.get(`https://finex.4help.tn/api/v1/regulationsbysoc/${localStorage.getItem('societe').slice(1, -1)}`).then(res => { return res.data })
            console.log(this.listeFacture)
        },
        async PostInvoice(data) {
            await axios.post("https://finex.4help.tn/api/v1/regulationss", data).then(async (res) => {
                if (res.status === 200 || res.status === 201) {
                    swal({
                        title: 'Success',
                        text: `Successfully added ${data.length} regulations!`,
                        icon: 'success',
                    });
                    
                    this.currentPage = 1
                    this.addingInvoice = false
                    this.data = []
                    this.fileUploaded = false
                } else {
                    swal({
                        title: 'Error',
                        text: `Server error.`,
                        icon: 'error',
                    });
                }
            }).finally(() => {
                 this.getFactureVente()
            })
            this.showPopup = true;
            this.isPopupVisible = true;
            
        },

        initselectedIndexes(n) {
            this.selectedIndexes = Array.from({ length: n }, () => true)
            this.toggleValue = true
        },
        getSelectedData() {
            let selectedData = []
            this.selectedIndexes.map((value, index) => {
                if (value) selectedData.push(this.data[index])
            })
            return selectedData
        },
        submitHandle() {
            this.PostInvoice(this.getSelectedData())
            this.getFactureVente()
            // window.location.reload(true);
        },
        async handleFileUpload() {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = ".xlsx, .xls";
            input.style.display = "none";
            input.addEventListener("change", async (event) => {
                const selectedFile = event.target.files[0];
                if (selectedFile) {
                    const file = selectedFile;
                    if (file) {
                        this.fileUploaded = true
                        const readFileAsync = (file) => {
                            return new Promise((resolve, reject) => {
                                const reader = new FileReader()
                                reader.onload = (e) => resolve(e.target.result)
                                reader.onerror = (e) => reject(e)
                                reader.readAsBinaryString(file)
                            });
                        };

                        try {
                            const data = await readFileAsync(file)
                            const workbook = read(data, { type: 'binary' })
                            const sheetName = workbook.SheetNames[0]
                            const sheet = workbook.Sheets[sheetName]
                            this.data = utils.sheet_to_json(sheet, { header: 1 })
                            //this.dataLabels = this.data[0]
                            
                            this.dataLabels = this.data[0].map(column => column.trim())
                            this.data[0].push('Societe');
                            

                            const dataModel = ['Type', 'Date','Payment_method','Amount','echeance','id',"Societe"];
                            if (JSON.stringify(this.dataLabels) !== JSON.stringify(dataModel)) {
                                swal({
                                    title: 'Error',
                                    text: `Fields missmatch, please use our template.`,
                                    icon: 'error',
                                })
                                this.data = []
                                return
                            }
                            this.data.shift()
                            this.data.map((row, index) => {
                                
                                row[1] = this.excelDateToJSDate(row[1])
                                row[4] = this.excelDateToJSDate(row[4])
                                row[6] = localStorage.getItem('societe').slice(1, -1) 
                            })
                            const keys = ['Type', 'Date','Payment_method','Amount','echeance','id','Societe'];
                            this.data = this.data.map(subArray => {
                                let obj = {};
                                keys.forEach((key, index) => {
                                    obj[key] = subArray[index];
                                });
                                return obj;
                            })
                            this.initselectedIndexes(this.data.length)
                        } catch (error) {
                            console.error(error)
                            // Handle errors here
                        }
                    }
                }
            });

            input.click();

        },
        processExcelData(jsonData) {
            // Process jsonData if needed
            // For example, you can filter unwanted rows, modify data, etc.
            return jsonData;
        },
        excelDateToJSDate(excelDateSerial) {
      const excelEpoch = new Date(1899, 11, 32); // Excel's epoch starts on December 30, 1899

      // Add the number of days to the Excel epoch
      const jsDate = new Date(excelEpoch.getTime());
      jsDate.setDate(jsDate.getDate() + excelDateSerial - 1);

      // Format the date as a string "YYYY-MM-DD"
      const formattedDate = jsDate.toISOString().split('T')[0];
      return formattedDate;
    },
    }
};
</script>

<style scoped>
.form-container {
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.file-upload-button {
    background-color: #082748;
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.file-upload-button:hover {
    background-color: #0056b3;
}

.accueil {
    text-align: center;
    padding: 4rem;
}

.accueil {
    text-align: center;
}

.card {
    min-height: 100%;
    margin-bottom: 0px !important;
}

h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #f2f2f2 !important;
    font-weight: bold;
    color: #333;
}


tr:nth-child(even) {
    background-color: #f2f2f2 !important;
}

tr:hover {
    background-color: #e0e0e0 !important;
    cursor: pointer;
    transition: background-color 0.3s;
}

</style>

<style>
.table.b-table > thead > tr > [aria-sort="none"], .table.b-table > tfoot > tr > [aria-sort="none"]{
    background-position-x: left !important;
    }
</style>